import {isHighSchoolStudent, isCommunityCollegeStudent, isEducatorDemoStudent} from 'shared/utils';
import {STEPS} from './constants';

const ccStaffDemoSteps = [STEPS.followColleges];

const educatorDemoSteps = [STEPS.achievementSelect, STEPS.followColleges];

const communityCollegeSteps = [
  STEPS.name,
  STEPS.birthday,
  STEPS.address,
  STEPS.institution,
  STEPS.transferTerm,
  STEPS.pellEligibility,
  STEPS.citizenship,
];

const highSchoolSteps = [
  STEPS.name,
  STEPS.birthday,
  STEPS.address,
  STEPS.institution,
  STEPS.graduationYear,
  STEPS.citizenship,
];

export const getStepOrder = ({
  inEU,
  addressInEU,
  institutionInEU,
  skipFollowCollegeStep,
  skipAchievementSelect,
  demoType,
  educationPhase,
}) => {
  if (isEducatorDemoStudent(demoType) && isCommunityCollegeStudent(educationPhase)) {
    return ccStaffDemoSteps;
  }

  if (isEducatorDemoStudent(demoType)) {
    return educatorDemoSteps;
  }

  let steps;

  if (isCommunityCollegeStudent(educationPhase)) {
    steps = communityCollegeSteps;
  } else {
    steps = highSchoolSteps;
  }

  if (!inEU && (addressInEU || institutionInEU)) {
    steps = [...steps, STEPS.notifications];
  }

  if (!skipAchievementSelect && isHighSchoolStudent(educationPhase)) {
    steps = [...steps, STEPS.achievementSelect];
  }

  if (!skipFollowCollegeStep) {
    steps = [...steps, STEPS.followColleges];
  }

  if (isHighSchoolStudent(educationPhase)) {
    steps = [...steps, STEPS.peerReferrals, STEPS.parentEmail];
  }

  return steps;
};

export const getNextStep = (currentStep, steps) => {
  const nextStepIndex = steps.indexOf(currentStep) + 1;
  if (nextStepIndex >= steps.length) {
    return STEPS.redirect;
  }

  return steps[nextStepIndex];
};

export const getPreviousStep = (currentStep, steps) => {
  const previousStepIndex = steps.indexOf(currentStep) - 1;
  if (previousStepIndex < 0) {
    return undefined;
  }

  return steps[previousStepIndex];
};
