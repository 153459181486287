import {forEach, pick, some} from 'lodash';
import {openModal} from 'shared/actions/modal';
import {updateUser} from 'shared/actions/student';
import req from 'shared/req';
import {createAddressString} from 'src/services/AssetService/utils';

export const UPDATE_SUGGESTED_ADDRESS = 'UPDATE_SUGGESTED_ADDRESS';
export const ADDRESS_VERIFIED = 'ADDRESS_VERIFIED';
export const MODAL_IDENTIFIER = 'addressVerification';

export const verifyAddress = (student, dispatch) => {
  const {
    addressLine1,
    addressLine2,
    city,
    subdivision,
    postalCode,
    addressVerified,
    confirmedUnverifiedAddress,
  } = student;

  const originalAddress = createAddressString(student);
  const url = `/students/verify_address?street=${encodeURIComponent(originalAddress)}`;

  return new Promise((resolve, reject) =>
    req({
      url,
      method: 'GET',
    })
      .then((response) => {
        if (response.length > 0) {
          const {
            components: {
              primary_number,
              street_name,
              street_predirection,
              street_postdirection,
              street_suffix,
              secondary_number,
              secondary_designator,
              city_name,
              state_abbreviation,
              zipcode,
              plus4_code,
            },
            delivery_line_1,
            last_line,
            metadata: {county_name, county_fips, latitude, longitude},
          } = response[0];

          let pattern = originalAddress;

          if (postalCode.match(/[0-9]{5}/)) {
            pattern = pattern + '(-[0-9]{4})?';
          }

          const regex = new RegExp(pattern, 'i');

          const smartyStreetsSuggestedAddressString = `${delivery_line_1}, ${last_line}`;
          const addressesMatch = smartyStreetsSuggestedAddressString.match(regex);

          const addressComponents = [
            primary_number,
            street_predirection,
            street_name,
            street_postdirection,
            street_suffix,
          ];
          let addressLine = '';
          forEach(addressComponents, (component) => {
            component ? (addressLine += `${component} `) : '';
          });
          addressLine = addressLine.trim();

          const suggestedAddress = {
            addressLine1: addressLine,
            addressLine2: secondary_number ? `${secondary_designator} ${secondary_number}` : '',
            city: `${city_name}`,
            subdivision: state_abbreviation,
            postalCode: `${zipcode}-${plus4_code}`,
            countyName: county_name,
            countyFips: county_fips,
            latitude,
            longitude,
            addressVerified,
            confirmedUnverifiedAddress,
          };

          if (addressesMatch) {
            const addressVerifiedData = {
              ...suggestedAddress,
              addressVerified: true,
              confirmedUnverifiedAddress: false,
            };
            updateUser(addressVerifiedData, '_id').then(() => {
              dispatch({
                type: ADDRESS_VERIFIED,
                addressVerifiedData,
              });
            });
          } else {
            dispatch({
              type: UPDATE_SUGGESTED_ADDRESS,
              originalAddress: {
                addressLine1,
                addressLine2,
                city,
                subdivision,
                postalCode,
              },
              suggestedAddress,
            });

            dispatch(openModal(MODAL_IDENTIFIER));
          }
        } else {
          dispatch({
            type: UPDATE_SUGGESTED_ADDRESS,
            originalAddress: {
              addressLine1,
              addressLine2,
              city,
              subdivision,
              postalCode,
            },
            suggestedAddress: null,
          });

          dispatch(openModal(MODAL_IDENTIFIER));
        }

        resolve();
      })
      .catch((errors) => {
        reject(errors);
      })
  );
};

export const shouldResetAddressVerified = (props) => {
  const {addressVerified, confirmedUnverifiedAddress, resetAddressVerified, fields} = props;

  if (!addressVerified && !confirmedUnverifiedAddress) {
    return;
  }

  const addressFieldNames = ['addressLine1', 'addressLine2', 'postalCode', 'city', 'subdivision'];
  const addressFields = pick(fields, addressFieldNames);

  if (some(addressFields, 'dirty')) {
    resetAddressVerified();
  }
};
