const ADDRESS_FORM_FIELDS = [
  'addressLine1',
  'addressLine2',
  'city',
  'subdivision',
  'country',
  'postalCode',
  'confirmedUnverifiedAddress',
  'addressVerified',
];

const INSTITUTION_FIELDS = [
  'currentInstitution.name',
  'currentInstitution.parentId',
  'currentInstitution.ineligibleCountry',
  'currentInstitution.country',
  'currentInstitution.subdivision',
  'currentInstitution.city',
  'currentInstitution.type',
  'currentInstitution.campuses',
  'currentInstitution.campus',
];

const COMMUNITY_COLLEGE_FIELDS = [
  'communityCollegeInfo.intendedMajor',
  'communityCollegeInfo.transferYear',
  'communityCollegeInfo.transferSemester',
  'communityCollegeInfo.lowIncome',
];

export const FORM_FIELDS = [
  ...ADDRESS_FORM_FIELDS,
  ...INSTITUTION_FIELDS,
  ...COMMUNITY_COLLEGE_FIELDS,
  'firstName',
  'lastName',
  'birthday',
  'replacementAccountType',
  'isUsCitizenOrResident',
  'countryOfCitizenship',
  'notificationOptIn',
  'highSchoolInfo.graduationYear',
  'followedCollegeIds',
  'educationPhase',
  'fourYearTransfer',
];

export const STEPS = {
  name: 'name',
  birthday: 'birthday',
  address: 'address',
  institution: 'institution',
  graduationYear: 'graduationYear',
  citizenship: 'citizenship',
  notifications: 'notifications',
  achievementSelect: 'achievementSelect',
  followColleges: 'followColleges',
  transferTerm: 'transferTerm',
  pellEligibility: 'pellEligibility',
  intendedMajor: 'intendedMajor',
  peerReferrals: 'peerReferrals',
  parentEmail: 'parentEmail',
  redirect: 'redirect', // redirect should never be displayed. Instead, redirects user to main app
};

export const REPLACEMENT_ACCOUNT_OPTIONS = {
  communityCollege: 'communityCollege',
  educator: 'educator',
  highSchool: 'highSchool',
  delete: 'delete',
};
