import {includes, isEmpty, last, size} from 'lodash';
import validateNameStep from 'shared/registration/validations/validate-name-step';
import req from 'shared/req';
import {
  ALLOWED_GRADUATION_YEARS,
  dateToUTC,
  isCommunityCollegeStudent,
  isEducatorDemoStudent,
} from 'shared/utils';
import validateIntendedMajorStep from 'transfer-student/registration/validations/validate-intended-major-step';
import validatePellEligibilityStep from 'transfer-student/registration/validations/validate-pell-eligibility-step';
import validateTransferTermStep from 'transfer-student/registration/validations/validate-transfer-term-step';
import {STEPS} from './constants';
import validateAddressStep from './validations/validate-address-step';
import validateBirthdayStep from './validations/validate-birthday-step';
import validateCitizenshipStep from './validations/validate-citizenship-step';
import validateGraduationYear from './validations/validate-graduation-year';
import validateInstitutionStep from './validations/validate-institution-step';

export const validate = (values, props) => {
  switch (props.onboardingStep) {
    case STEPS.name: {
      return validateNameStep(values);
    }
    case STEPS.birthday: {
      return validateBirthdayStep(values);
    }
    case STEPS.address: {
      return validateAddressStep(values);
    }
    case STEPS.institution: {
      return validateInstitutionStep(values);
    }
    case STEPS.graduationYear: {
      return validateGraduationYear(values);
    }
    case STEPS.citizenship: {
      return validateCitizenshipStep(values);
    }
    case STEPS.transferTerm: {
      return validateTransferTermStep(values);
    }
    case STEPS.pellEligibility: {
      return validatePellEligibilityStep(values);
    }
    case STEPS.intendedMajor: {
      return validateIntendedMajorStep(values);
    }
  }
  return {};
};

export const getInitialOnboardingStep = (user) => {
  if (isEducatorDemoStudent(user.demoType) && isCommunityCollegeStudent(user.educationPhase)) {
    return STEPS.followColleges;
  } else if (isEducatorDemoStudent(user.demoType)) {
    return STEPS.achievementSelect;
  } else if (isCommunityCollegeStudent(user.educationPhase)) {
    return getInitialCommunityCollegeStep(user);
  } else {
    return getInitialHighSchoolStep(user);
  }
};

const getInitialHighSchoolStep = (user) => {
  if (size(validateNameStep(user))) {
    return STEPS.name;
  } else if (size(validateBirthdayStep(user))) {
    return STEPS.birthday;
  } else if (
    size(validateAddressStep(user)) ||
    (!user.addressVerified && !user.confirmedUnverifiedAddress)
  ) {
    return STEPS.address;
  } else if (size(validateInstitutionStep(user))) {
    return STEPS.institution;
  } else if (size(validateGraduationYear(user))) {
    return STEPS.graduationYear;
  } else {
    return STEPS.citizenship;
  }
};

const getInitialCommunityCollegeStep = (user) => {
  if (size(validateNameStep(user))) {
    return STEPS.name;
  } else if (size(validateBirthdayStep(user))) {
    return STEPS.birthday;
  } else if (
    size(validateAddressStep(user)) ||
    (!user.addressVerified && !user.confirmedUnverifiedAddress)
  ) {
    return STEPS.address;
  } else if (size(validateInstitutionStep(user))) {
    return STEPS.institution;
  } else if (size(validateTransferTermStep(user))) {
    return STEPS.transferTerm;
  } else if (size(validatePellEligibilityStep(user))) {
    return STEPS.pellEligibility;
  } else if (size(validateIntendedMajorStep(user))) {
    return STEPS.intendedMajor;
  } else {
    return STEPS.citizenship;
  }
};

export const formatBirthday = (ISOString) => {
  const date = dateToUTC(new Date(ISOString));

  return date
    .toLocaleString('en-us', {month: '2-digit', day: '2-digit', year: 'numeric'})
    .replace(/\//g, ' / ');
};

export const updateUser = (values) => {
  if (values.birthday && values.birthday instanceof Date) {
    values.birthday = values.birthday.toISOString();
  }

  if (values.currentInstitution && isEmpty(values.currentInstitution.parentId)) {
    values.currentInstitution = null;
  }

  if (values.isUsCitizenOrResident === 'decline') {
    values.isUsCitizenOrResident = null;
  }

  return req({url: '/v1/users/me?fields=_id', method: 'PATCH', data: values});
};

export const predictGradYear = (birthday) => {
  let gradYear = birthday.getFullYear() + 18;

  if (birthday.getMonth() > 7) {
    gradYear += 1;
  }

  if (includes(ALLOWED_GRADUATION_YEARS, gradYear)) {
    return gradYear;
  } else if (gradYear < ALLOWED_GRADUATION_YEARS[0]) {
    return ALLOWED_GRADUATION_YEARS[0];
  } else {
    return last(ALLOWED_GRADUATION_YEARS);
  }
};
