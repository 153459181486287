import {Capacitor} from '@capacitor/core';
import $ from 'jquery';
import {concat, filter, find, forEach, includes, isNil, isNumber, map, some, sumBy} from 'lodash';
import fp, {flow} from 'lodash/fp';
import PropTypes from 'prop-types';
import {UserType} from 'src/types/enums';
import {validateEmail} from 'src/utils/validations';
import vars from 'src/utils/vars';
import req from './req';

/**
 * Get the shape of a Redux Forms Field.
 * With argument, require that the value of the field is of the specified type.
 */
export const fieldShape = (type) => {
  const shape = {onChange: PropTypes.func.isRequired};
  if (type) {
    shape.value = PropTypes[type];
  }
  return PropTypes.shape(shape);
};

export const parseIntOrNull = (str) => {
  const value = parseInt(str, 10);
  return isNaN(value) ? null : value;
};

/**
 * Formats a number such that there is at least one decimal point.
 *
 * Examples:
 *  gpaFormat(1)
 *    => '1.0'
 *
 * gpaFormat(2.25)
 *   => '2.25'
 *
 * @param num
 * @returns {string}
 */
export const gpaFormat = (num) => {
  if (isNil(num)) {
    return '';
  } else {
    return num.toFixed(2).replace(/0$/, '');
  }
};

/**
 * Return a new array with the item at the specified index removed.
 *
 * Example:
 *   withoutIndex([1, 2, 3, 4], 2) === [1, 2, 4]
 */
export const withoutIndex = (array, index) => concat(array.slice(0, index), array.slice(index + 1));

// https://gist.github.com/solenoid/1372386
export function objectId() {
  const timestamp = ((Date.now() / 1000) | 0).toString(16);

  return (
    timestamp +
    'xxxxxxxxxxxxxxxx'
      .replace(/[x]/g, function () {
        return ((Math.random() * 16) | 0).toString(16);
      })
      .toLowerCase()
  );
}

// Generate a version 4 UUID.
// Do not use this for cryptographic purposes.
// Based on https://stackoverflow.com/a/2117523
export function uuid4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (token) {
    const rand = (Math.random() * 16) | 0;
    const number = token === 'x' ? rand : (rand & 0x3) | 0x8;
    return number.toString(16);
  });
}

export function parseQueryString(queryString) {
  const query = {};

  const processValue = (rawValue) => {
    if (`${parseInt(rawValue)}` === `${rawValue}`) {
      return parseInt(rawValue);
    } else if (`${parseFloat(rawValue)}` === `${rawValue}`) {
      return parseFloat(rawValue);
    }

    return rawValue;
  };

  const handleStringifiedObject = (string, value, obj = {}) => {
    const matches = string.match(/\[([^\[]*)\](.*)/);
    const key = matches[1];
    const remainingKey = matches[2];

    if (remainingKey) {
      obj[key] = handleStringifiedObject(remainingKey, value, obj[key]);
    } else {
      obj[key] = value;
    }

    return obj;
  };

  if (queryString !== '') {
    const keyValueStrings = queryString.split('&');

    for (let i = 0; i < keyValueStrings.length; i++) {
      const keyValuePair = keyValueStrings[i].split('=');
      let key = decodeURIComponent(keyValuePair[0]);
      // converts the string value to an integer if it is an integer, or a float if it is a float,
      // otherwise it will be left as a string
      const value = processValue(decodeURIComponent(keyValuePair[1]));

      // first we check if it is an array value and if it is we make sure it becomes an array
      // in the return object, if not we check to see if it is an encoded object, if it is
      // we make sure it becomes a nested object in the return object, otherwise we just
      // add the value to the object under the key
      if (key.slice(key.length - 2) === '[]') {
        key = key.slice(0, key.length - 2);
        query[key] = query[key] || [];
        query[key].push(value);
      } else if (key.match(/\[(.*)\]/)) {
        const parentKey = key.slice(0, key.indexOf('['));
        query[parentKey] = handleStringifiedObject(key, value, query[parentKey]);
      } else {
        query[key] = value;
      }
    }
  }

  return query;
}

export function secondHalfOfSchoolYear() {
  const JULY = 6;
  const now = new Date();
  const currentMonth = now.getMonth();
  const secondHalf = currentMonth < JULY;
  return secondHalf;
}

export function last6SchoolYears() {
  const JULY = 6;
  const now = new Date();
  const currentMonth = now.getMonth();
  const currentYear = now.getFullYear();
  const year = currentMonth < JULY ? currentYear - 1 : currentYear;

  const schoolYears = [];

  for (let i = 0; i < 6; i++) {
    schoolYears.push(`${year - i}-${year - i + 1}`);
  }

  return schoolYears;
}

export function last6Years() {
  const now = new Date();
  const currentYear = now.getFullYear();

  const recentYears = [];

  for (let i = 0; i < 6; i++) {
    recentYears.push(currentYear - i);
  }

  return recentYears;
}

export function createLocalField(callback) {
  const field = {
    value: '',
    onChange(e) {
      field.value = e.target.value;
      field.touched = true;
      field.invalid = false;
      callback();
    },
    invalid: false,
    active: false,
    touched: false,
    onFocus() {
      field.active = true;
      field.touched = true;
      callback();
    },
    onBlur() {
      field.active = false;
      field.touched = true;
      callback();
    },
  };

  return field;
}

export function withCommas(number) {
  number = number.toString();
  const numberParts = number.split('.');

  numberParts[0] = numberParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  return numberParts.join('.');
}

export function roundDecimal(number, decimals = 1) {
  const divisor = Math.pow(10, decimals);

  return Math.round(number * divisor) / divisor;
}

export const ordinalNumber = (number) => {
  const lastTwoDigits = number.toString().slice(-2);

  // handles 0, 10 - 19
  if (lastTwoDigits.length > 1 && lastTwoDigits[0] === '1') {
    return number + 'th';
  } else if (lastTwoDigits.length === 1 && lastTwoDigits[0] === '0') {
    return number;
  }

  const lastDigit = lastTwoDigits.slice(-1);

  switch (lastDigit) {
    case '1':
      return number + 'st';
    case '2':
      return number + 'nd';
    case '3':
      return number + 'rd';
    default:
      return number + 'th';
  }
};

export function titleize(string, replaceSeparators = false) {
  if (typeof string !== 'string') {
    return string;
  }

  string = string.toLowerCase().replace(/(?:^|\s|-|_)\S/g, function (c) {
    return c.toUpperCase();
  });

  if (replaceSeparators) {
    string = string.replace(/[_-]/g, ' ');
  }

  return string;
}

export function calculateOnTrack(credits, graduationYear) {
  credits = Math.floor(credits);

  const onTrackInfo = {
    onTrackPercentage: 0,
    text: [],
    totalCredits: 0,
    finished: false,
    goalCredits: 0,
  };

  // Calculate amount of credits student should try to get
  // depending on their graduator year: 15 credits for seniors
  // and juniors, 10 for sophomores, 5 for freshmen.
  //
  // Default to 15 for past students and if no graduationYear
  // is given, and default to 5 for future students.
  let goalCredits = 5;
  if (!graduationYear || graduationYear <= CURRENT_SCHOOL_YEAR) {
    goalCredits = 15;
  } else if (graduationYear > CURRENT_SCHOOL_YEAR && graduationYear < CURRENT_SCHOOL_YEAR + 4) {
    goalCredits = 20 - 5 * (graduationYear - CURRENT_SCHOOL_YEAR);
  }

  onTrackInfo.goalCredits = goalCredits;
  onTrackInfo.totalCredits = credits;

  if (goalCredits > onTrackInfo.totalCredits) {
    onTrackInfo.onTrackPercentage = onTrackInfo.totalCredits / goalCredits;
    onTrackInfo.text.push(`${onTrackInfo.totalCredits} of ${goalCredits} courses added`);
  } else {
    onTrackInfo.onTrackPercentage = 1;
    onTrackInfo.text.push(`${goalCredits} of ${goalCredits} courses added`);
    onTrackInfo.finished = true;
  }

  return onTrackInfo;
}

export function resizeImage(file) {
  return new Promise((resolve, reject) => {
    const SIZE = 512;
    const reader = new FileReader();
    const canvas = document.createElement('canvas');
    const canvasContext = canvas.getContext('2d');

    canvas.width = SIZE;
    canvas.height = SIZE;

    reader.onload = (e) => {
      const image = new Image();
      const src = e.target.result;

      image.onload = () => {
        const shorter = Math.min(image.width, image.height);
        const longer = Math.max(image.width, image.height);
        const offset = (longer - shorter) / 2;

        if (image.width === image.height) {
          canvasContext.drawImage(image, 0, 0, SIZE, SIZE);
        } else if (image.width > image.height) {
          canvasContext.drawImage(image, offset, 0, shorter, shorter, 0, 0, SIZE, SIZE);
        } else if (image.height > image.width) {
          canvasContext.drawImage(image, 0, offset, shorter, shorter, 0, 0, SIZE, SIZE);
        }

        canvas.toBlob((blob) => {
          resolve(blob);
        });
      };

      image.onerror = (err) => {
        reject(err);
      };

      image.src = src;
    };

    reader.onerror = (err) => {
      reject(err);
    };

    reader.readAsDataURL(file);
  });
}

const isFinalGrade = (grade) =>
  grade.period && (grade.period === 'F' || grade.period === 'S' || grade.period === 'W');

export function calculateGpa(courses, graduationYear) {
  let totalValue = 0;
  let totalCredits = 0;

  const eligibleCourses = filter(
    courses,
    (course) =>
      course.schoolYear &&
      course.schoolYear >= (graduationYear - 4).toString() &&
      !course.new &&
      includes(CORE_SUBJECTS, course.subject)
  );

  const coursesWithGrades = sumBy(eligibleCourses, ({grades}) =>
    some(grades, 'gradeInput') ? 1 : 0
  );

  if (coursesWithGrades < 4) {
    return null;
  }

  eligibleCourses.forEach((course) => {
    if (!course.credits) {
      return;
    }

    const finalGrade = find(
      course.grades,
      (grade) => isNumber(grade.gpaValue) && isFinalGrade(grade)
    );

    if (finalGrade) {
      totalValue += course.credits * finalGrade.gpaValue;
      totalCredits += course.credits;
    } else {
      const otherGrades = filter(
        course.grades,
        (grade) => !(grade.enabled === false || isFinalGrade(grade))
      );
      const creditsPerGrade = course.credits / otherGrades.length;

      otherGrades.forEach(({gpaValue}) => {
        if (!isNumber(gpaValue)) {
          return;
        }

        totalValue += gpaValue * creditsPerGrade;
        totalCredits += creditsPerGrade;
      });
    }
  });

  if (totalCredits > 0) {
    const gpa = totalValue / totalCredits;
    return gpa.toFixed(2).replace(/0$/, '');
  } else {
    return '0.0';
  }
}

export function calculateCollegeGpa(courses) {
  let totalValue = 0.0;
  let totalCreditHours = 0.0;

  const eligibleCourses = filter(courses, (course) => !course.new);

  const coursesWithGrades = sumBy(eligibleCourses, ({grade}) => (grade.gradeInput ? 1 : 0));

  if (!coursesWithGrades || coursesWithGrades < 4) {
    return null;
  }

  eligibleCourses.forEach((course) => {
    if (!course.creditHours || isNil(course.grade.gpaValue)) {
      return;
    }

    totalValue += course.grade.gpaValue * course.creditHours;
    totalCreditHours += course.creditHours;
  });

  if (totalCreditHours > 0) {
    const gpa = totalValue / totalCreditHours;
    return gpa.toFixed(2).replace(/0$/, '');
  } else {
    return '0.0';
  }
}

// constants
export const MONTH_ABBRVS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const MONTH_FULL = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

// Maps US state and territory abbreviations to full name.
export const US_ABBV_MAP = {
  AA: 'Armed Forces America',
  AE: 'Armed Forces Europe',
  AK: 'Alaska',
  AL: 'Alabama',
  AP: 'Armed Forces Pacific',
  AR: 'Arkansas',
  AS: 'America Samoa',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  FM: 'Micronesia',
  FL: 'Florida',
  GA: 'Georgia',
  GU: 'Guam',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MH: 'Marshall Islands',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MP: 'Northern Mariana Islands',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PW: 'Palau',
  PA: 'Pennsylvania',
  PR: 'Puerto Rico',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VI: 'Virgin Island',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming',
};

export const DISABILITIES = [
  'Auditory Processing Disorder',
  'Autism Spectrum Disorder',
  'Blind or Low Vision',
  'Deaf or Hard of Hearing',
  'Dyslexia / Dyscalculia / Dysgraphia',
  'Intellectual Disability',
  'Non-Verbal Learning Disability',
  'Visual Perceptual / Motor Disability',
  'ADD / ADHD',
  'Other',
  '',
];

// Gets days in month, January = 0, adding plus 1 cause javascript can be dumb.
// https://stackoverflow.com/questions/1184334/get-number-days-in-a-specified-month-using-javascript
export const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();

export const FIELD_NAME_TO_DISPLAY = {
  score: 'Score',
  score_english: 'English',
  score_reading: 'Reading',
  score_math: 'Math',
  score_science: 'Science',
  score_reading_and_writing: 'Reading & Writing',
  score_critical_reading: 'Critical Reading',
  score_writing: 'Writing',
  score_essay: 'Essay',
  score_multiple_choice: 'Multiple Choice',
  score_mathematics: 'Math',
  score_writing_language: 'Writing & Language',
};

// From app/models/courses/course_subject.rb
export const CORE_SUBJECTS = ['Math', 'Science', 'English', 'Social Studies', 'Foreign Language'];

export const NON_CORE_SUBJECTS = [
  'Fine Arts',
  'Non Core',
  'Financial Literacy',
  'Technical Education',
  'Physical Education',
];

export const MULTIMEDIA_CATEGORIES = [
  'Painting and Drawing',
  'Sculpture',
  'Photography',
  'Illustration',
  'Animation',
  'Graphic Design',
  'Interior Design',
  'Fashion Design',
  'Jewelry and Metal Arts',
  'Dance and Performing Arts',
  'Film and Theater Arts',
  'Other Visual Arts',
  'Other Design',
  'Other Performing Art',
];

export const ALL_SUBJECTS = CORE_SUBJECTS.concat(NON_CORE_SUBJECTS);

export const SUBJECT_TYPE_OPTIONS = ALL_SUBJECTS.map((type) => ({
  _id: type,
  value: type,
  text: type,
}));

export const CREDIT_OPTIONS = [
  {_id: '1-credit', value: '1', text: 'Full'},
  {_id: '3/4-credit', value: '0.75', text: '3/4'},
  {_id: '2/3-credit', value: '0.66', text: '2/3'},
  {_id: '1/2-credit', value: '0.5', text: '1/2'},
  {_id: '1/3-credit', value: '0.33', text: '1/3'},
  {_id: '1/4-credit', value: '0.25', text: '1/4'},
  {_id: '0-credit', value: '0', text: 'None'},
];

export const COURSE_TYPES_ENUM = {
  regular: 'Regular',
  honors: 'Honors',
  advanced: 'Advanced',
  ap: 'AP',
  ib: 'IB',
  pre_ap: 'Pre-AP',
  pre_ib: 'Pre-IB',
  dual_enrollment: 'Dual Enrollment',
  certification: 'Certification',
};

export const COURSE_TYPES = Object.values(COURSE_TYPES_ENUM);

export const GENDER_OPTIONS = [
  'Agender',
  'Androgyne',
  'Androgynes',
  'Androgynous',
  'Asexual',
  'Bigender',
  'Cisgender Female',
  'Cisgender Male',
  'Female to male trans man',
  'Female to male transgender man',
  'Female to male transsexual man',
  'Female',
  'Gender Fluid',
  'Gender Nonconforming',
  'Gender Questioning',
  'Gender neutral',
  'Genderqueer',
  'Hermaphrodite',
  'Intersex man',
  'Intersex person',
  'Intersex woman',
  'Male to female trans woman',
  'Male to female transgender woman',
  'Male to female transsexual woman',
  'Male',
  'Neither',
  'Neutrois',
  'Non-binary',
  'Other',
  'Pangender',
  'Polygender',
  'Trans Female',
  'Trans Male',
  'Trans Person',
  'Transexual Female',
  'Transexual Male',
  'Transexual Person',
  'Transexual',
  'Transfeminine',
  'Transgender Female',
  'Transgender Male',
  'Transgender Person',
  'Transmasculine',
  'Two-spirit person',
  'Two-spirit',
];

export const GENDERS = ['F', 'M', 'NB', 'Prefer Not to Answer'];

const today = () => new Date();

export const TODAY = today();

const marchFirst = () => new Date(today().getFullYear(), 2, 1);

export const MARCH_FIRST = marchFirst();

const mayFirst = () => new Date(today().getFullYear(), 4, 1);

export const MAY_FIRST = mayFirst();

export const julyFirst = () => new Date(today().getFullYear(), 6, 1);

export const JULY_FIRST = julyFirst();

export const currentSchoolYear = () =>
  julyFirst() > today() ? julyFirst().getFullYear() : julyFirst().getFullYear() + 1;

export const CURRENT_SCHOOL_YEAR = currentSchoolYear();

const graduationSchoolYear = () => map(new Array(4), (_, idx) => currentSchoolYear() + idx);

export const GRADUATION_SCHOOL_YEAR = graduationSchoolYear();

export const highSchoolYears = (graduationYear) =>
  map(new Array(4), (_val, idx) => `${graduationYear - (idx + 1)}-${graduationYear - idx}`);

export const SEASONS = ['Summer', 'Fall', 'Winter', 'Spring'];

export const ALLOWED_GRADUATION_YEARS = (() => {
  let totalYears = 4;
  if (TODAY >= MAY_FIRST && TODAY < JULY_FIRST) {
    totalYears = 5;
  }

  return map(new Array(totalYears), (val, idx) => CURRENT_SCHOOL_YEAR + idx);
})();

export const graduationYearToGradeLevel = () => {
  const yearToGradeLevel = {};
  const gradeLevels = ['senior', 'junior', 'sophomore', 'freshman'];

  forEach(graduationSchoolYear(), (year, i) => {
    yearToGradeLevel[year] = gradeLevels[i];
  });

  return yearToGradeLevel;
};

export const gradeLevelForGraduationYear = (year) => graduationYearToGradeLevel()[year];

// takes an array of school year strings (such as 2014-2015) and consolidates the ranges
// input does not need to be sorted.
// ex. input of ["2014-2015", "2015-2016", "2016-2017", "2019-2020"] would produce: ["2014 - 2017", "2019 - 2020"]
export const consolidateSchoolYears = (schoolYears) => {
  const consolidateSchoolYearsForGroup = (groupedSchoolYears) =>
    flow(
      fp.map((schoolYear) => schoolYear.split('-').map((year) => parseInt(year))),
      fp.flatten,
      yearRanges
    )(groupedSchoolYears);

  return flow(
    groupByConsecutiveSchoolYears,
    fp.map(consolidateSchoolYearsForGroup),
    fp.flatten
  )(schoolYears);
};

/**
 * Takes in an array of school years and returns an array of arrays each containing only consecutive school years
 *
 * @param {Array} schoolYears - array of school years in the format '2015-2016'
 * @returns {Array} Array of arrays each containing only consecutive school years
 */
export const groupByConsecutiveSchoolYears = (schoolYears = []) => {
  schoolYears.sort();

  const groupedSchoolYears = [];
  let consecutiveSchoolYears = [];

  let startOfPreviousSchoolYear = null;

  forEach(schoolYears, (schoolYear) => {
    const startYear = parseInt(schoolYear.split('-')[0]);

    // if we have a gap in school years, such as 2015-2016 followed by 2017-2018 (skipping 2016-2017), we add this
    // group to the array of grouped school years and start a new array of consecutive school years
    if (startOfPreviousSchoolYear && startYear - startOfPreviousSchoolYear > 1) {
      groupedSchoolYears.push(consecutiveSchoolYears);
      consecutiveSchoolYears = [];
    }

    startOfPreviousSchoolYear = startYear;

    consecutiveSchoolYears.push(schoolYear);
  });

  // if there are any school years in the latest group, make sure to push that group into the return value
  if (consecutiveSchoolYears.length) {
    groupedSchoolYears.push(consecutiveSchoolYears);
  }

  return groupedSchoolYears;
};

// takes an array of years (does not need to be sorted) and produces an array of strings of the year ranges
// ex. input of [2014, 2015, 2016, 2017, 2019, 2020] would produce: ["2014 - 2017", "2019 - 2020"]
export const yearRanges = (years) => {
  years.sort();

  const ranges = [];

  let rangeYears = [];

  forEach(years, (year) => {
    if (rangeYears.length === 0) {
      rangeYears.push(year);
    } else {
      const beginning = rangeYears[0];
      const ending = rangeYears.length === 2 ? rangeYears[1] : null;

      if ((!ending && year - beginning <= 1) || year - ending <= 1) {
        if (beginning !== year) {
          rangeYears = [beginning, year];
        }
      } else {
        ranges.push(rangeYears);
        rangeYears = [year];
      }
    }
  });

  ranges.push(rangeYears);

  return ranges.map((range) => range.join(' - '));
};

const STRIP_MOBILE_REG_EXP = /[()\s-]/g;
const MOBILE_REG_EXP = /^\d+$/;

export const validateMobileOrEmail = (value, callback) => {
  const stripped = value.replace(STRIP_MOBILE_REG_EXP, '');
  const isMobile = MOBILE_REG_EXP.test(stripped);
  let valid;

  if (isMobile) {
    const digits = value.replace(/\D/g, '');
    const groups = digits.match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    const masked = !groups[2]
      ? groups[1]
      : '(' + groups[1] + ') ' + groups[2] + (groups[3] ? '-' + groups[3] : '');
    value = masked;
    valid = digits.length > 9;
  } else {
    valid = validateEmail(value);
  }

  if (typeof callback === 'function') {
    callback(valid, value, isMobile);
  }

  return valid;
};

export const validateLength = (name, string, maxLength) => {
  if (string && string.length > maxLength) {
    return `${name} too long (${maxLength} characters max).`;
  }
  return null;
};

// modified version of http://stackoverflow.com/questions/2897155/get-cursor-position-in-characters-within-a-text-input-field
export const getCaretPosition = ($el) => {
  // Initialize
  let caretPosition = 0;

  // IE Support
  if (document.selection) {
    // Set focus on the element
    $el.focus();

    // To get cursor position, get empty selection range
    const selectionRange = document.selection.createRange();

    // Move selection start to 0 position
    selectionRange.moveStart('character', -$el.value.length);

    // The caret position is selection length
    caretPosition = selectionRange.text.length;

    // eslint-disable-next-line eqeqeq
  } else if ($el.selectionStart || $el.selectionStart == '0') {
    // Firefox support
    caretPosition = $el.selectionStart;
  }

  return caretPosition;
};

// To do, test with IE and add support for IE as necessary
// Currently only used in an internal tool
export const getSelectionEnd = ($el) => {
  let selectionEnd = null;
  // eslint-disable-next-line eqeqeq
  if ($el.selectionEnd || $el.selectionEnd == '0') {
    selectionEnd = $el.selectionEnd;
  }

  return selectionEnd;
};

export const isValidDate = (year, month, day) => {
  const d = new Date(year, month, day);

  return d.getFullYear() === year && d.getMonth() === month && d.getDate() === day;
};

// Returns "underage", "underage-community-college", or "valid"
const getCommunityCollegeAgeStatus = (date) => {
  const ageInYears = new Date(Date.now() - date).getUTCFullYear() - 1970;
  if (ageInYears < 13) {
    return 'underage';
  } else if (ageInYears < 15) {
    return 'underage-community-college';
  }
  return 'valid';
};

// Returns "overage", or "valid"
// underage check is handled on the backend after submitting so that we can trigger data deletion
export const getHighSchoolAgeStatus = (date) => {
  const ageInYears = new Date(Date.now() - date).getUTCFullYear() - 1970;
  if (ageInYears >= 21) {
    return 'overage';
  }
  return 'valid';
};

// Underage validation handled on the backend
const getAdultLearnerAgeStatus = (_date) => 'valid';

export const getStudentAgeStatus = (educationPhase, date) => {
  if (educationPhase === UserType.CC_STUDENT) {
    return getCommunityCollegeAgeStatus(date);
  } else if (educationPhase === UserType.ADULT_LEARNER) {
    return getAdultLearnerAgeStatus(date);
  }

  return getHighSchoolAgeStatus(date);
};

export const isHighSchoolStudent = (educationPhase) => educationPhase === 'high-school';

export const isCommunityCollegeStudent = (educationPhase) => educationPhase === 'community-college';

export const isUndergraduateStudent = (educationPhase) => educationPhase === 'undergraduate';

export const isEducatorDemoStudent = (demoType) => demoType === 'Educator';

export const isHighSchoolEducator = (type) => type === 'Teacher' || type === 'Counselor';

export const isHighSchoolMentor = (type) => type === 'Mentor';

export const isCommunityCollegeStaff = (type) => type === 'CommunityCollegeStaff';

// modified version of http://stackoverflow.com/questions/512528/set-cursor-position-in-html-textbox
export const setCaretPosition = ($el, caretPos) => {
  if ($el) {
    if ($el.createTextRange) {
      const range = $el.createTextRange();
      range.move('character', caretPos);
      range.select();
    } else {
      if ($el.selectionStart) {
        $el.focus();
        $el.setSelectionRange(caretPos, caretPos);
      } else {
        $el.focus();
      }
    }
  }
};

export const isPrintableKeycode = (keycode) =>
  (keycode > 47 && keycode < 58) || // number keys
  keycode === 32 ||
  keycode === 13 || // spacebar & return key(s) (if you want to allow carriage returns)
  (keycode > 64 && keycode < 91) || // letter keys
  (keycode > 95 && keycode < 112) || // numpad keys
  (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
  (keycode > 218 && keycode < 223); // [\]' (in order)

// This is borrowed from https://stackoverflow.com/questions/6828637/escape-regexp-strings#answer-9537421
export const escapeRegExp = (str) => str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');

// used instead of $(document).ready(fn);
// http://youmightnotneedjquery.com/#ready
export const ready = (fn) => {
  // Waiting for https://raiseme.atlassian.net/browse/RM-1784
  // setupTracer();
  if (
    document.attachEvent ? document.readyState === 'complete' : document.readyState !== 'loading'
  ) {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn, {capture: true});
  }
};

// Returns a boolean indicating whether or not the student
// can earn from a college given an earning status.
export const earning = (earningStatus) =>
  ['churned', 'scholarships_not_yet_available', 'limited_scholarships'].indexOf(earningStatus) ===
  -1;

const pluralizeMap = {
  criterion: 'criteria',
};

export function pluralize(count, string) {
  const mappedValue = pluralizeMap[string];
  return count === 1 ? string : mappedValue || string + 's';
}

// Add password requirements popover to applicable fields
export const initializePasswordPopover = () => {
  $('[data-toggle="password-tooltip"]').tooltip({
    template:
      '\
    <div\
      class="opens-password-tooltip tooltip shared-tooltip bottom tooltip-list"\
      aria-label="Password must include at least 8 characters, at least 1 uppercase letter, at least 1 lowercase letter, and at least 1 number."\
    >\
      <div class="tooltip-arrow"></div>\
      <div class="tooltip-inner">\
      </div>\
    </div>\
    ',
    placement: 'bottom',
    html: true,
    title:
      'Password must include:<ul><li>At least 8 characters</li><li>At least 1 lowercase letter</li><li>At least 1 uppercase letter</li><li>At least 1 number</li></ul>',
    trigger: 'hover focus active',
  });
};

// Returns whether or not the visitor is in the EU
export const ipInfo = () => req({url: '/v1/ip-info', method: 'GET'});

// Return whether correct phone verification code entered
export const checkPhoneCode = (phone_number, verification_code) =>
  req({
    url: '/v1/verify-phone-number/check-code',
    data: {phone_number, code: verification_code},
    method: 'POST',
  });

/* eslint-disable */
// this script is provided by FullStory
const loadFullStory = () => {
  window['_fs_debug'] = false;
  window['_fs_host'] = 'fullstory.com';
  window['_fs_script'] = 'edge.fullstory.com/s/fs.js';
  window['_fs_org'] = 'CSFST';
  window['_fs_namespace'] = 'FS';
  (function (m, n, e, t, l, o, g, y) {
    if (e in m) {
      if (m.console && m.console.log) {
        m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
      }
      return;
    }
    g = m[e] = function (a, b, s) {
      g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
    };
    g.q = [];
    o = n.createElement(t);
    o.async = 1;
    o.crossOrigin = 'anonymous';
    o.src = 'https://' + _fs_script;
    y = n.getElementsByTagName(t)[0];
    y.parentNode.insertBefore(o, y);
    g.identify = function (i, v, s) {
      g(l, {uid: i}, s);
      if (v) g(l, v, s);
    };
    g.setUserVars = function (v, s) {
      g(l, v, s);
    };
    g.event = function (i, v, s) {
      g('event', {n: i, p: v}, s);
    };
    g.anonymize = function () {
      g.identify(!!0);
    };
    g.shutdown = function () {
      g('rec', !1);
    };
    g.restart = function () {
      g('rec', !0);
    };
    g.log = function (a, b) {
      g('log', [a, b]);
    };
    g.consent = function (a) {
      g('consent', !arguments.length || a);
    };
    g.identifyAccount = function (i, v) {
      o = 'account';
      v = v || {};
      v.acctId = i;
      g(o, v);
    };
    g.clearUserCookie = function () {};
    g.setVars = function (n, p) {
      g('setVars', [n, p]);
    };
    g._w = {};
    y = 'XMLHttpRequest';
    g._w[y] = m[y];
    y = 'fetch';
    g._w[y] = m[y];
    if (m[y])
      m[y] = function () {
        return g._w[y].apply(this, arguments);
      };
    g._v = '1.3.0';
  })(window, document, window['_fs_namespace'], 'script', 'user');
};
/* eslint-enable */

/**
 * Load 3rd-party analytic modules: Fullstory and Segment
 */
export const loadAnalytics = () => {
  if (!window.analytics || window.analytics.initialized === true) return;

  if (!vars.SEGMENT_KEY) {
    console.warn('Unable to load analytics, missing segment key');
    return;
  }

  const analytics = window.analytics;
  analytics.load(vars.SEGMENT_KEY);

  const track = ({userId, justLoggedIn, justSignedUp}) => {
    const eventProperties = {
      screen_width: window.screen.width,
      screen_height: window.screen.height,
      platform: Capacitor.getPlatform(),
    };

    if (userId) {
      if (justLoggedIn || justSignedUp) {
        analytics.alias(userId);
      }

      if (justLoggedIn) {
        analytics.track('Logged In', eventProperties);
      }

      if (justSignedUp) {
        analytics.track('Signed Up', eventProperties);
      }
    }
    analytics.page(window.location.pathname, eventProperties);
  };

  req({
    url: '/v1/analytics-data/me',
  }).then((data) => {
    track(data);

    if (data.userId) {
      loadFullStory();
      window.FS.identify(data.userId);
    }
  });
};

export const dateToUTC = (date) =>
  new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

export const dateStringIsValid = (string) => !isNaN(Date.parse(string));

export const WEIGHTED_TO_UNWEIGHTED_GPA = [
  {grade: 'A', unweighted: 4, weighted: 5},
  {grade: 'A-', unweighted: 3.7, weighted: 4.7},
  {grade: 'B+', unweighted: 3.3, weighted: 4.3},
  {grade: 'B', unweighted: 3, weighted: 4},
  {grade: 'B-', unweighted: 2.7, weighted: 3.7},
  {grade: 'C+', unweighted: 2.3, weighted: 3.3},
  {grade: 'C', unweighted: 2.0, weighted: 3},
  {grade: 'C-', unweighted: 1.7, weighted: 2.7},
  {grade: 'D+', unweighted: 1.3, weighted: 2.3},
  {grade: 'D', unweighted: 1, weighted: 2},
  {grade: 'F', unweighted: 0, weighted: 1},
];

export const CORE_COURSE_EXAMPLES = [
  {core: true, subject: 'Math', name: 'Honors Algebra'},
  {core: true, subject: 'Science', name: 'Chemistry'},
  {core: true, subject: 'Social Studies', name: 'AP World History'},
  {core: true, subject: 'English', name: 'Literature and Composition'},
  {core: true, subject: 'Foreign Language', name: 'Spanish 2'},
  {core: false, subject: 'Fine Arts', name: 'Concert Band'},
  {core: false, subject: 'Non-Core', name: 'Religion'},
  {core: false, subject: 'Technical Education', name: 'Web Design'},
];

export const rankFormat = (value, rank, category = null) =>
  value
    ? `#${value} ${rank} ${typeOfRank(rank, category)} Rank`
    : `Not nationally ranked by ${rank}`;
export const rateFormat = (value, rate) =>
  value ? `${Math.round(value * 10) / 10}% ${rate}` : `${rate} Not Available`;
export const priceFormat = (value, price) =>
  value ? `$${withCommas(Math.round(value))} ${price}` : `${price} Not Available`;
export const typeOfRank = (rank, category) => {
  if (rank === 'Forbes' || !category) return '';
  return `${category === 'Liberal Arts' ? 'Liberal Arts' : 'National'}`;
};

export const VALID_PASSWORD_REGEX = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,128}/;
export const INVALID_PASSWORD_ERROR_MESSAGE =
  'Password must include at least 8 characters, at least 1 uppercase letter, at least 1 lowercase letter, and at least 1 number.';

// Given a value, copies the value to clipboard.
export const copy = (copyValue) => {
  const hiddenInput = document.createElement('input');
  hiddenInput.style = 'position: absolute; z-index: -1; border: 0;';
  hiddenInput.value = copyValue;
  document.body.appendChild(hiddenInput);
  hiddenInput.select();
  document.execCommand('copy');
  document.body.removeChild(hiddenInput);
};

export const EARNING_STATUSES = [
  'earning',
  'submitted',
  'earning_deadline_soon',
  'submission_period_deferred',
];

export const activitiesOrCoursesExistForYear = ({
  year,
  activities,
  bulkEntryCourses,
  institutionId,
}) => {
  for (const activityKey in activities) {
    const activity = activities[activityKey];
    if (activity.institution === 'inst-' + institutionId && activity.schoolYears.includes(year)) {
      return true;
    }
  }

  for (const courseKey in bulkEntryCourses) {
    const course = bulkEntryCourses[courseKey];
    if (course.institution._id === institutionId && course.schoolYear === year && course.name) {
      return true;
    }
  }

  return false;
};

export const pathWithoutModal = (modalPath) =>
  window.location.pathname.match(new RegExp('^(.*?)\\' + modalPath))[1];

export const convertMetersToMiles = (meters) =>
  // 1609 meters in one mile
  Math.round(meters / 1609);

export const SORT_TO_FIELD = {
  'average-net-price': 'averageNetPrice',
  tuition: 'tuition',
  'acceptance-rate': 'acceptanceRate',
  'six-year-grad-rate': 'sixYearGraduationRate',
  'forbes-ranking': 'forbesRank',
  'us-news-ranking': 'usNewsRank',
};

export const HS_SORT_OPTIONS = [
  {value: 'A-Z', type: '', order: 'asc'},
  {value: 'Z-A', type: '', order: 'desc'},
  {value: 'Distance', type: 'distance', order: 'asc'},
  {value: 'Earnings: Highest', type: 'earnings', order: 'desc'},
  {value: 'Earnings: Lowest', type: 'earnings', order: 'asc'},
  {value: 'Top-Ranked: US News', type: 'us-news-ranking', order: 'asc'},
  {value: 'Top-Ranked: Forbes', type: 'forbes-ranking', order: 'asc'},
  {value: 'Tuition: Highest', type: 'tuition', order: 'desc'},
  {value: 'Tuition: Lowest', type: 'tuition', order: 'asc'},
  {value: 'Acceptance Rate: Highest', type: 'acceptance-rate', order: 'desc'},
  {value: 'Acceptance Rate: Lowest', type: 'acceptance-rate', order: 'asc'},
  {value: 'Avg Net Price: Highest', type: 'average-net-price', order: 'desc'},
  {value: 'Avg Net Price: Lowest', type: 'average-net-price', order: 'asc'},
  {value: 'Grad Rate: Highest', type: 'six-year-grad-rate', order: 'desc'},
  {value: 'Grad Rate: Lowest', type: 'six-year-grad-rate', order: 'asc'},
];

export const TRANSFER_SORT_OPTIONS = [
  {value: 'A-Z', type: '', order: 'asc'},
  {value: 'Z-A', type: '', order: 'desc'},
  {value: 'Distance', type: 'distance', order: 'asc'},
  {value: 'Earnings: Highest', type: 'earnings', order: 'desc'},
  {value: 'Earnings: Lowest', type: 'earnings', order: 'asc'},
  {value: 'Top-Ranked: US News', type: 'us-news-ranking', order: 'asc'},
  {value: 'Top-Ranked: Forbes', type: 'forbes-ranking', order: 'asc'},
  {value: 'Tuition: Highest', type: 'tuition', order: 'desc'},
  {value: 'Tuition: Lowest', type: 'tuition', order: 'asc'},
  {value: 'Grad Rate: Highest', type: 'six-year-grad-rate', order: 'desc'},
  {value: 'Grad Rate: Lowest', type: 'six-year-grad-rate', order: 'asc'},
];

export const EDUCATOR_SORT_OPTIONS = [
  {value: 'A-Z', type: '', order: 'asc'},
  {value: 'Z-A', type: '', order: 'desc'},
  {value: 'Distance', type: 'distance', order: 'asc'},
  {value: 'Top-Ranked: US News', type: 'us-news-ranking', order: 'asc'},
  {value: 'Top-Ranked: Forbes', type: 'forbes-ranking', order: 'asc'},
  {value: 'Tuition: Highest', type: 'tuition', order: 'desc'},
  {value: 'Tuition: Lowest', type: 'tuition', order: 'asc'},
  {value: 'Acceptance Rate: Highest', type: 'acceptance-rate', order: 'desc'},
  {value: 'Acceptance Rate: Lowest', type: 'acceptance-rate', order: 'asc'},
  {value: 'Avg Net Price: Highest', type: 'average-net-price', order: 'desc'},
  {value: 'Avg Net Price: Lowest', type: 'average-net-price', order: 'asc'},
  {value: 'Grad Rate: Highest', type: 'six-year-grad-rate', order: 'desc'},
  {value: 'Grad Rate: Lowest', type: 'six-year-grad-rate', order: 'asc'},
];

export const PUBLIC_SORT_OPTIONS = [
  {value: 'A-Z', type: '', order: 'asc'},
  {value: 'Z-A', type: '', order: 'desc'},
  {value: 'Distance', type: 'distance', order: 'asc'},
  {value: 'Top-Ranked: US News', type: 'us-news-ranking', order: 'asc'},
  {value: 'Top-Ranked: Forbes', type: 'forbes-ranking', order: 'asc'},
  {value: 'Tuition: Highest', type: 'tuition', order: 'desc'},
  {value: 'Tuition: Lowest', type: 'tuition', order: 'asc'},
  {value: 'Acceptance Rate: Highest', type: 'acceptance-rate', order: 'desc'},
  {value: 'Acceptance Rate: Lowest', type: 'acceptance-rate', order: 'asc'},
  {value: 'Avg Net Price: Highest', type: 'average-net-price', order: 'desc'},
  {value: 'Avg Net Price: Lowest', type: 'average-net-price', order: 'asc'},
  {value: 'Grad Rate: Highest', type: 'six-year-grad-rate', order: 'desc'},
  {value: 'Grad Rate: Lowest', type: 'six-year-grad-rate', order: 'asc'},
];

export const objectDiff = (item1, item2) =>
  Object.keys(item1).filter((key) => !item2.hasOwnProperty(key));
