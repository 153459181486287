import {isHighSchoolStudent} from 'shared/utils';

const validateGraduationYear = (values) => {
  const errors = {};

  if (!values.highSchoolInfo.graduationYear && isHighSchoolStudent(values.educationPhase)) {
    errors.highSchoolInfo = {};
    errors.highSchoolInfo.graduationYear = 'Graduation year is required.';
  }

  return errors;
};
export default validateGraduationYear;
