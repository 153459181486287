import errorPlaceholders from 'src/static/errors.json';
import {validateContainsLatinCharacter} from 'src/utils/validations';

const validateNameStep = (values, errors = {}) => {
  if (!values.firstName) {
    errors.firstName = 'First name is required.';
  } else if (!validateContainsLatinCharacter(values.firstName)) {
    errors.firstName = errorPlaceholders.text.requiresLatinCharacters;
  }

  if (!values.lastName) {
    errors.lastName = 'Last name is required.';
  } else if (!validateContainsLatinCharacter(values.lastName)) {
    errors.lastName = errorPlaceholders.text.requiresLatinCharacters;
  }

  return errors;
};

export default validateNameStep;
