const validateAddressStep = (values) => {
  const errors = {};

  if (!values.addressLine1) {
    errors.addressLine1 = 'Address is required.';
  }

  if (!values.country) {
    errors.country = 'Country is required.';
  }

  if (!values.city) {
    errors.city = 'City is required.';
  }

  if (values.country === 'US') {
    // This will test for a 5 digit or 5 plus 4 digit code
    if (!/^\d{5}(-\d{4})?$/.test(values.postalCode)) {
      errors.postalCode = 'Please enter a valid postal code.';
    }

    if (!values.subdivision) {
      errors.subdivision = 'State is required.';
    }
  }

  return errors;
};

export default validateAddressStep;
