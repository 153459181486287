export const STRIP_MOBILE_REG_EXP = /[()\s-]/g;
export const MOBILE_REG_EXP = /^\d+$/;
const LATIN_CHARACTER_REG_EXP = /\p{Script=Latin}/u;
const LATIN_AND_LATIN_SUPPLEMENT = /[\u0040-\u00FF]/u;

export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const isMobile = (value: string): boolean => {
  const stripped = value.replace(STRIP_MOBILE_REG_EXP, '');
  return MOBILE_REG_EXP.test(stripped);
};

export function validateContainsLatinCharacter(value: string): boolean {
  return LATIN_AND_LATIN_SUPPLEMENT.test(value) && LATIN_CHARACTER_REG_EXP.test(value);
}

export interface EmailOrPhoneValidation {
  original: string;
  formatted: string;
  valid: boolean;
  type: 'email' | 'phone' | 'unknown';
}

export function validatePhone(value: string): EmailOrPhoneValidation {
  const digits = value.replace(/\D/g, '');
  const groups = digits.match(/(\d{0,3})(\d{0,3})(\d{0,4})/) || Array(4);
  const masked = !groups[2]
    ? groups[1]
    : '(' + groups[1] + ') ' + groups[2] + (groups[3] ? '-' + groups[3] : '');
  return {
    original: value,
    formatted: masked,
    valid: digits.length > 9,
    type: 'phone',
  };
}

export function validatePhoneOrEmail(value: string): EmailOrPhoneValidation {
  if (isMobile(value)) {
    return validatePhone(value);
  }

  return {
    original: value,
    formatted: value,
    valid: validateEmail(value),
    type: 'email',
  };
}
