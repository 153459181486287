import {get, isNil} from 'lodash';

const validateInstitutionStep = (values) => {
  const errors = {};

  if (isNil(get(values, 'currentInstitution.parentId'))) {
    errors.currentInstitution = {};
    errors.currentInstitution.parentId = 'Please select your Institution';
  }

  return errors;
};
export default validateInstitutionStep;
