import {isCommunityCollegeStudent} from 'shared/utils';

const validateTransferTermStep = (values) => {
  const errors = {};

  if (
    (isCommunityCollegeStudent(values.educationPhase) &&
      !values.communityCollegeInfo.transferSemester) ||
    !values.communityCollegeInfo.transferYear
  ) {
    errors.communityCollegeInfo = {};
    errors.communityCollegeInfo.transferSemester = 'Transfer term is required.';
  }

  return errors;
};
export default validateTransferTermStep;
