import {isCommunityCollegeStudent} from 'shared/utils';

const validateIntendedMajorStep = (values) => {
  const errors = {};

  if (
    !values.communityCollegeInfo.intendedMajor &&
    isCommunityCollegeStudent(values.educationPhase)
  ) {
    errors.communityCollegeInfo = {};
    errors.communityCollegeInfo.intendedMajor =
      'Major is required. If you\'re not sure, select "Undecided."';
  }

  return errors;
};
export default validateIntendedMajorStep;
