export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';

export function closeModal(identifier) {
  return {
    type: CLOSE_MODAL,
    identifier,
  };
}

export function openModal(identifier) {
  return {
    type: OPEN_MODAL,
    identifier,
  };
}
