import {objectId} from 'shared/utils';

export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION';

export const addNotification = (message) => {
  message.id = message.id || objectId();
  message.type = message.type || 'success';
  message.template = message.template || 'simple';

  return {
    type: ADD_NOTIFICATION,
    message,
  };
};

export const removeNotification = (id) => ({
  type: REMOVE_NOTIFICATION,
  id,
});

// Adds a notification with the message and type given, after the duration has passed it removes the notification
export const flashMessage = (text, type, options = {}, duration = 3000) => {
  // Printing warning for easy stack trace
  console.warn(`FlashMessage type=${type}, text=${text}`);

  const message = {
    ...options,
    id: objectId(),
    duration,
    text,
    type,
  };

  return (dispatch) => {
    dispatch(addNotification(message));

    setTimeout(() => {
      dispatch(removeNotification(message.id));
    }, duration);
  };
};

export const changesSaved = () => flashMessage('Changes saved!');

export const errorMessage = (text) =>
  flashMessage(text || 'Whoops! Something went wrong on our end. Please try again.', 'error');
