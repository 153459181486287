import {getStudentAgeStatus} from 'shared/utils';
import text from 'src/components/form/onboarding/text.json';

const birthdayErrors = text.birthdayErrors;
const validateBirthdayStep = (values) => {
  const errors = {};
  if (!values.birthday) {
    errors.birthday = birthdayErrors.required;
    return errors;
  }

  const birthday = new Date(values.birthday);
  const ageStatus = getStudentAgeStatus(values.educationPhase, birthday);

  if (ageStatus === 'underage') {
    errors.birthday = birthdayErrors.underage;
  } else if (ageStatus === 'overage') {
    errors.birthday = birthdayErrors.overage;
  } else if (ageStatus === 'underage-community-college') {
    errors.birthday = birthdayErrors.underageCommunityCollege;
  }
  return errors;
};

export default validateBirthdayStep;
