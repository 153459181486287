import {find, omit} from 'lodash';
import {verifyAddress} from 'shared/account-settings/address-verification';
import {getUser} from 'shared/actions/student';
import {hideCookiePrefsFooter, showCookiePrefsFooter} from 'shared/cookie-preference/helpers';
import {ipInfo} from 'shared/utils';
import AssetService from 'src/services/AssetService';
import {Track} from 'src/utils/analytics';
import {STEPS} from './constants';
import {getNextStep, getStepOrder} from './navigation';
import {getInitialOnboardingStep, updateUser} from './utils';

export const INCREMENT_STEP = 'REGISTRATION/INCREMENT_STEP';
export const DECREMENT_STEP = 'REGISTRATION/DECREMENT_STEP';
export const UPDATE_USER = 'REGISTRATION/UPDATE_USER';
export const RECEIVE_USER = 'REGISTRATION/RECEIVE_USER';
export const RECEIVE_LOCATION = 'REGISTRATION/RECEIVE_LOCATION';
export const UPDATE_ADDRESS_EU_STATUS = 'REGISTRATION/UPDATE_ADDRESS_EU_STATUS';
export const RESET_ADDRESS_VERIFIED = 'REGISTRATION/RESET_ADDRESS_VERIFIED';
export const UPDATE_INSTITUTION_EU_STATUS = 'REGISTRATION/UPDATE_INSTITUTION_EU_STATUS';

export const decrementStep = () => ({type: DECREMENT_STEP});
export const incrementStep = (nextStep, user) => ({
  type: INCREMENT_STEP,
  nextStep,
  user: omit(user, ['replacementAccountType']),
});

const receiveUser = (data) => ({type: RECEIVE_USER, ...data});

export const USER_FIELDS = [
  'firstName',
  'lastName',
  'birthday',
  'addressLine1',
  'addressLine2',
  'city',
  'subdivision',
  'country',
  'postalCode',
  'addressVerified',
  'confirmedUnverifiedAddress',
  'highSchoolInfo',
  'currentInstitution',
  'onboardingCompletedAt',
  'isUsCitizenOrResident',
  'countryOfCitizenship',
  'followedCollegeIds',
  'signupSkipFollowedCollegesStep',
  'demoType',
  'educationPhase',
  'communityCollegeInfo',
  'mobile',
  'referralSource',
  'mobileVerified',
];

export const USER_FIELDS_TO_OMIT = ['replacementAccountType'];

export const resetAddressVerified = () => (dispatch) => dispatch({type: RESET_ADDRESS_VERIFIED});

export const fetchUser = () => (dispatch) =>
  getUser('me', USER_FIELDS.join(',')).then((user) => {
    const onboardingStep = getInitialOnboardingStep(user);
    if (user.birthday) {
      user.birthday = new Date(user.birthday);
    }

    dispatch(receiveUser({onboardingStep, user: user}));

    return user;
  });

export const loadRegistration = () => (dispatch) => {
  dispatch(fetchUser()).then((user) => {
    user.country && setAddressEUStatus(user.country);

    user.currentInstitution &&
      user.currentInstitution.country &&
      dispatch(setInstitutionEUStatus(user.currentInstitution.country));
  });

  dispatch(fetchLocation());
};

export const fetchLocation = () => (dispatch) =>
  ipInfo().then((ipLocation) => {
    dispatch({type: RECEIVE_LOCATION, ...ipLocation});
  });

export const setAddressEUStatus = (addressCountry) => (dispatch) =>
  AssetService.getCountryList(false).then((countriesList) => {
    const country = find(countriesList, {code: addressCountry});

    if (country.inEU) {
      showCookiePrefsFooter();
    } else {
      hideCookiePrefsFooter();
    }

    dispatch({type: UPDATE_ADDRESS_EU_STATUS, addressInEU: country && country.inEU});
  });

export const setInstitutionEUStatus = (institutionCountry) => (dispatch) =>
  AssetService.getCountryList(false).then((countriesList) => {
    const country = find(countriesList, {code: institutionCountry});

    dispatch({type: UPDATE_INSTITUTION_EU_STATUS, institutionInEU: country && country.inEU});
  });

const shouldVerifyAddress = (values) =>
  !values.confirmedUnverifiedAddress && !values.addressVerified && values.country === 'US';

const nextStepFromState = (state) => {
  const steps = getStepOrder({
    inEU: state.inEU,
    addressInEU: state.addressInEU,
    institutionInEU: state.institutionInEU,
    skipFollowCollegeStep: state.signupSkipFollowedCollegesStep,
    skipAchievementSelect: false,
    demoType: state.demoType,
    educationPhase: state.educationPhase,
  });

  return getNextStep(state.onboardingStep, steps);
};

export const onSubmit = (values, state, finishOnboarding) => (dispatch) => {
  if (shouldVerifyAddress(values) && state.onboardingStep === STEPS.address) {
    return updateUser(values).then(() => verifyAddress({...values}, dispatch));
  } else {
    const nextStep = nextStepFromState(state);

    if (nextStep === STEPS.redirect) {
      Track.onboardingStepComplete(state._id, state.onboardingStep);
      Track.onboardingComplete(state._id, state.educationPhase);
      return updateUser({
        ...values,
        onboardingCompletedAt: Date.now(),
      }).then(() => {
        finishOnboarding();
      });
    }

    dispatch(incrementStep(nextStep, values));
    return updateUser(values);
  }
};

export const submitAddressVerification = (values) => (dispatch, getState) => {
  const registrationState = getState().registration;
  const nextStep = nextStepFromState(registrationState);

  dispatch(incrementStep(nextStep, values));
  return updateUser(values);
};
