import {isCommunityCollegeStudent} from 'shared/utils';

const validatePellEligibilityStep = (values) => {
  const errors = {};

  if (!values.communityCollegeInfo.lowIncome && isCommunityCollegeStudent(values.educationPhase)) {
    errors.communityCollegeInfo = {};
    errors.communityCollegeInfo.intendedMajor = 'Need-based aid eligibility is required.';
  }

  return errors;
};
export default validatePellEligibilityStep;
